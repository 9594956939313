<template>
  <div>
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-1">
        <!--Cancel Button-->
        <AllocationCancelBtn
          @passModalClose="$emit('passModalClose', false)"
          v-if="selectedComponent === 'AllocationRadioGroup'"
        />
        <!--Back Button-->
        <AllocationBackBtn v-else @passBack="$emit('passBack', $event)" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "AllocationButtonGroup",
  props: ["actionBtn", "selectedComponent"],
  components: {
    AllocationCancelBtn: () => import("./AllocationCancelBtn.vue"),
    AllocationBackBtn: () => import("./AllocationBackBtn.vue"),
  },
  data() {
    return {};
  },
};
</script>